import React from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from './components/heroSection';
import Services from './components/services';
import WhyChooseUs from './components/choose';
import Internship from './components/internship';
import AffiliationsSection from './components/affilations';

const Home = () => {
  return (
    <div className='bg-gradient-to-b from-blue-50 to-purple-50'>
      {/* SEO Optimization with React Helmet */}
      <Helmet>
        <title>VMAD Technologies | Leading IT Solutions & Internship Programs</title>
        <meta name="description" content="VMAD Technologies offers top-notch IT solutions, including software development, app development, and machine learning. Explore our certified internship programs." />
        <meta name="keywords" content="IT solutions provider, software development, web development, app development, machine learning, internships, certified internships, VMAD Technologies,IT internships, web development internships, app development internships, certified internships, IT solutions, custom software development, digital marketing, machine learning services" />
        <meta name="author" content="VMAD Technologies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://vmadtechnologies.in" />
      </Helmet>

      {/* Content Sections */}
      <HeroSection />
      <Services />
      <WhyChooseUs />
      <Internship />
      <AffiliationsSection />
    </div>
  );
};

export default Home;
