import React, { useEffect } from 'react';
import { FaCode, FaMobileAlt, FaBrain, FaUsers, FaBullhorn, FaPencilRuler, FaVideo, FaMicrophone } from 'react-icons/fa';
import { motion } from 'framer-motion';
import msmeLogo from "../assets/msme.png";
import isoLogo from "../assets/iso.png";
import startupIndiaLogo from "../assets/digial.png";
import projectImage1 from "../assets/khetibuddy.png"; 
import projectImage2 from "../assets/sparked.png";
import projectImage3 from "../assets/faceguard.png";
import { Helmet } from 'react-helmet';

// Services Section
const services = [
  { title: "App Development", icon: <FaMobileAlt />, description: "We build fast, user-friendly mobile apps tailored to your business needs." },
  { title: "Web Development", icon: <FaCode />, description: "From static websites to complex web applications, we deliver solutions to power your business." },
  { title: "Machine Learning Development", icon: <FaBrain />, description: "Leverage data-driven machine learning solutions for enhanced decision-making and automation." },
  { title: "Social Media Management", icon: <FaUsers />, description: "Boost your online presence with our social media management services." },
  { title: "UI/UX Design", icon: <FaPencilRuler />, description: "Create visually stunning and user-friendly interfaces for web and mobile apps." },
  { title: "Digital Marketing", icon: <FaBullhorn />, description: "Grow your brand with effective digital marketing strategies and campaigns." },
  { title: "Video Editing", icon: <FaVideo />, description: "Professional video editing services to make your content stand out." },
  { title: "Transcription", icon: <FaMicrophone />, description: "Accurate transcription services for audio and video content." },
  { title: "Custom Software Development", icon: <FaCode />, description: "Tailored software solutions to meet your unique business requirements." }
];

// Recent Projects
const recentProjects = [
  { name: "KhetiBuddy", description: "An agriculture management platform for farmers.", image: projectImage1 },
  { name: "SparkEd", description: "An edtech web app enabling students to learn and collaborate seamlessly.", image: projectImage2 },
  { name: "FaceGuard", description: "A platform to take attendance using licve facial feature detecttion.", image: projectImage3},
];

// Testimonials
const testimonials = [
  { name: "Aarav Singh", feedback: "VMAD Technologies exceeded my expectations. The app they developed for us runs flawlessly!" },
  { name: "Meera Desai", feedback: "We saw a 40% increase in online engagement after VMAD revamped our digital marketing strategy." },
  { name: "Rohan Kumar", feedback: "Their machine learning solutions helped us streamline our internal processes." },
  { name: "Anjali Rao", feedback: "The team at VMAD is incredibly knowledgeable and supportive throughout the project." },
  { name: "Vikram Patil", feedback: "Outstanding service! They delivered everything on time and within budget." }
];

// Expert Tech Team
const techTeam = [
  { name: "Ritik Mishra", experience: "2+ years", projects: "10+ successful projects", certifications: "React Certified, AWS Certified", tech: "React, Node.js, Python", icon: <FaCode /> },
  { name: "Harshit Patel", experience: "3+ years", projects: "20+ successful projects", certifications: "ML Certified, Cloud Certified", tech: "Angular, Django, TensorFlow", icon: <FaBrain /> },
  { name: "Mukul Singh", experience: "4+ years", projects: "25+ successful projects", certifications: "Java Certified, Flutter Certified", tech: "Java, Spring Boot, Flutter", icon: <FaUsers /> },
];

const ServicesPage = () => {
  useEffect(() => {
    // Initialize testimonials display
    const testimonialElements = document.querySelectorAll('.testimonial');
    let currentIndex = 0;

    const showTestimonial = () => {
      testimonialElements.forEach((el, index) => {
        el.classList.toggle('hidden', index !== currentIndex);
      });
      currentIndex = (currentIndex + 1) % testimonialElements.length;
    };

    const intervalId = setInterval(showTestimonial, 2000); // Change every 3 seconds
    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <Helmet>
        <title>Our Services - VMAD Technologies</title>
        <meta name="description" content="Discover our comprehensive range of services including app development, web development, digital marketing, and more." />
        <link rel="canonical" href="/our-services" />
      </Helmet>
      <section className="relative bg-gradient-to-r from-purple-800 to-indigo-600 text-white py-20 mt-5 transition duration-300 ease-in-out transform hover:-translate-y-1">
        <div className="max-w-7xl mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Empowering Your Business</h1>
          <p className="text-lg md:text-xl mb-6">With Innovative IT Solutions & Certified Expertise.</p>
          <button className="bg-white text-purple-800 px-6 md:px-8 py-2 md:py-3 rounded-lg font-semibold hover:bg-gray-200 transition-all" onClick={() => document.getElementById('services').scrollIntoView({ behavior: 'smooth' })}>
            Explore Our Services
          </button>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-3xl md:text-4xl font-bold text-purple-800 text-center mb-10">Our Services</h2>
          <p className="text-gray-600 text-center mb-10">Discover the wide range of services we offer to empower your business.</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div 
                key={index} 
                className="flex items-start space-x-4 p-4 border border-gray-200 rounded-lg hover:shadow-lg transition-shadow duration-300 bg-gradient-to-r from-purple-50 to-purple-100"
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ delay: index * 0.1 }}
              >
                <div className="text-4xl md:text-5xl text-purple-600">{service.icon}</div>
                <div>
                  <h3 className="text-lg md:text-2xl font-semibold text-gray-800">{service.title}</h3>
                  <p className="text-gray-600">{service.description}</p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Get a Free Consultation Section */}
          <div className="mt-12 text-center">
            <h3 className="text-2xl md:text-3xl font-bold text-purple-800 mb-4">Get a Free Consultation</h3>
            <p className="text-gray-600 mb-6">Ready to take your business to the next level? Contact us for a free consultation!</p>
            <button className="bg-purple-800 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg hover:bg-purple-700 transition-all" onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
              Request Consultation
            </button>
          </div>
        </div>
      </section>

      {/* Recent Projects Section */}
      <section className="py-16 bg-gradient-to-b from-purple-50 to-purple-100">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-3xl md:text-4xl font-bold text-purple-800 text-center mb-10">Recent Projects</h2>
          <p className="text-gray-600 text-center mb-10">Here are some of the successful projects we've delivered.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {recentProjects.map((project, index) => (
              <div key={index} className="p-4 md:p-6 bg-white rounded-lg shadow-md transition-transform hover:scale-105">
                <img src={project.image} alt={project.name} className="w-full h-48 object-cover rounded-lg mb-4" />
                <h3 className="text-lg md:text-2xl font-semibold text-gray-800 mb-2">{project.name}</h3>
                <p className="text-gray-600">{project.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-gradient-to-b from-white to-purple-50">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-3xl md:text-4xl font-bold text-purple-800 text-center mb-10">What Our Clients Say</h2>
          <div className="relative">
            <div className="flex justify-center mb-6">
              {testimonials.map((testimonial, index) => (
                <div key={index} className={`testimonial ${index === 0 ? '' : 'hidden'} w-full max-w-xl mx-auto p-4 border border-gray-200 rounded-lg shadow-md`}>
                  <p className="text-gray-600 italic">"{testimonial.feedback}"</p>
                  <h4 className="mt-4 font-bold text-gray-800">{testimonial.name}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Expert Tech Team Section */}
      <section className="py-16 bg-gradient-to-b from-purple-50 to-purple-100">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-3xl md:text-4xl font-bold text-purple-800 text-center mb-10">Meet Our Expert Tech Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {techTeam.map((member, index) => (
              <div key={index} className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <div className="flex items-center mb-4">
                  <div className="text-4xl text-purple-600">{member.icon}</div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-800">{member.name}</h3>
                    <p className="text-gray-600">{member.experience}</p>
                  </div>
                </div>
                <p className="text-gray-600 mb-2">Projects: {member.projects}</p>
                <p className="text-gray-600 mb-2">Certifications: {member.certifications}</p>
                <p className="text-gray-600">Technologies: {member.tech}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 bg-white">
  <div className="max-w-7xl mx-auto px-6">
    <h2 className="text-3xl md:text-4xl font-bold text-purple-800 text-center mb-10">
      Certifications & Affiliations
    </h2>
    <div className="flex flex-wrap justify-center space-x-6">
      <div className="flex justify-center items-center mb-4">
        <img
          src={msmeLogo}
          alt="MSME Certification"
          className="h-16 md:h-20 object-contain"
        />
      </div>
      {/* <div className="flex justify-center items-center mb-4">
        <img
          src={isoLogo}
          alt="ISO Certification"
          className="h-16 md:h-20 object-contain"
        />
      </div> */}
      <div className="flex justify-center items-center mb-4">
        <img
          src={startupIndiaLogo}
          alt="Startup India Certification"
          className="h-16 md:h-20 object-contain"
        />
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

export default ServicesPage;
